import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text } from "../components/Core";
import CTA from "../sections/nightshift/CTA";
import PostDetails from "../sections/blog/PostDetails";

import heroImg from  "../assets/nightshift/blog-password-security.jpeg";

const BlogRegular = () => {
  return (
    <>
      <PageWrapper>
        <Section className="pb-0">
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center text-left">
              <Col lg="10">
                <Title variant="hero" className="text-center">Infrastructure: The New Cyber Resiliancy</Title>
                <PostDetails 
                    heroImg={heroImg}
                    imgAlt="Password Security"
                  >
                    <p>ToDo</p>
                    
                </PostDetails>
              </Col>
            </Row>
          </Container>
        </Section>
        <CTA />
      </PageWrapper>
    </>
  );
};
export default BlogRegular;
